<template>
  <div class="main__container main__padding">
    <h2>Einstellungen</h2>
    <h3 class="mb-15">Passwort ändern</h3>
    <p class="mh-20 success__message" v-if="successMessage">
      {{ successMessage }}
    </p>
    <form>
      <div class="searchbox searchbox--medium row__start mt-30">
        <input
          :type="seePasswordOld ? 'text' : 'password'"
          v-model="passwordOld"
          placeholder="altes Passwort"
        />
        <div @click="seePasswordOld = !seePasswordOld">
          <SeeSvg
            :classes="
              seePasswordOld ? 'svg__fill--primary' : 'svg__fill--grey700'
            "
          />
        </div>
      </div>
      <p class="error__message mt-4p" v-if="passwordOldError">
        {{ passwordOldError }}
      </p>
      <div
        class="searchbox searchbox--medium row__start mt-25"
        :class="
          passwordNewValid === null
            ? ''
            : passwordNewValid
            ? 'searchbox--green'
            : 'searchbox--red'
        "
      >
        <input
          :type="seePasswordNew ? 'text' : 'password'"
          v-model="passwordNew"
          placeholder="neues Passwort"
          @keyup="checkPasswordStrength"
        />
        <div @click="seePasswordNew = !seePasswordNew">
          <SeeSvg
            :classes="
              seePasswordNew ? 'svg__fill--primary' : 'svg__fill--grey700'
            "
          />
        </div>
      </div>
      <p class="error__message mt-4p" v-if="passwordNewError">
        {{ passwordNewError }}
      </p>
      <div
        class="searchbox searchbox--medium row__start mt-25"
        :class="
          confirmPasswordValid === null
            ? ''
            : confirmPasswordValid
            ? 'searchbox--green'
            : 'searchbox--red'
        "
      >
        <input
          :type="seeConfirmPassword ? 'text' : 'password'"
          v-model="confirmPassword"
          placeholder="Passwort bestätigen"
          autocomplete
          @keyup="checkPasswordStrength"
        />
        <div @click="seeConfirmPassword = !seeConfirmPassword">
          <SeeSvg
            :classes="
              seeConfirmPassword ? 'svg__fill--primary' : 'svg__fill--grey700'
            "
          />
        </div>
      </div>
      <p class="error__message mt-4p" v-if="confirmPasswordError">
        {{ confirmPasswordError }}
      </p>
    </form>
    <div
      class="button button--big mh-40"
      :class="!passwordNewValid || !confirmPasswordValid ? 'button--grey' : ''"
      @click.stop="prepareChangePassword"
    >
      <p class="text__white">Passwort ändern</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Settings",
  data() {
    return {
      passwordOld: "",
      seePasswordOld: false,
      passwordOldError: "",

      passwordNew: "",
      seePasswordNew: false,
      passwordNewValid: null,
      passwordNewError: "",

      confirmPassword: "",
      seeConfirmPassword: false,
      confirmPasswordValid: null,
      confirmPasswordError: "",

      successMessage: "",
      isUpdating: false,
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    async prepareChangePassword() {
      if (
        !this.passwordNewValid ||
        !this.confirmPasswordValid ||
        this.isUpdating
      ) {
        return;
      }
      this.passwordOldError = "";
      this.successMessage = "";
      let target = event.target;
      if (target.classList.contains("button")) {
        target = target.childNodes[0];
      }
      target.innerHTML = "<div class='loading'></div>";
      this.isUpdating = true;
      let data = {
        oldPassword: this.passwordOld,
        newPassword: this.passwordNew,
        confirmPassword: this.confirmPassword,
      };
      const succeded = await this.changePassword(data);
      if (succeded === true) {
        this.successMessage = "Sie haben erfolgreich das Passwort geändert";
      } else if (succeded) {
        this.passwordOldError = succeded;
      }

      target.innerHTML = " Passwort ändern";
      this.isUpdating = false;
    },
    checkPasswordStrength() {
      const password = this.passwordNew;
      this.successMessage = "";
      this.passwordNewError = "";
      this.confirmPasswordError = "";
      this.passwordNewValid = false;
      if (!/[A-Z]/.test(password)) {
        this.passwordNewError = "Mindestens ein Großbuchstaben.";
        return;
      }
      if (!/[a-z]/.test(password)) {
        this.passwordNewError = "Mindestens ein Kleinbuchstaben.";
        return;
      }
      if (!/[0-9]/.test(password)) {
        this.passwordNewError = "Mindestens eine Zahl.";
        return;
      }
      if (!/[^A-Za-z0-9]/.test(password)) {
        this.passwordNewError = "Mindestens ein Sonderzeichen.";
        return;
      }
      if (password.length <= 5) {
        this.passwordNewError = "Mindestens 6 Zeichen.";
        return;
      }
      this.passwordNewValid = true;
      this.confirmPasswordValid = false;

      if (this.passwordNew === this.confirmPassword) {
        this.confirmPasswordValid = true;
      } else {
        this.confirmPasswordError = "Passwörter stimmen überein.";
      }
    },
  },
  created() {
    if (!this.token) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  computed: {
    ...mapGetters(["token"]),
  },
};
</script>
