<template>
    <div class="main__container main__padding">
        <h2>Finde hier Deinen Einstieg, um durchzustarten!</h2>
        <div class="row__start row__start--stretch dashboard__row">
            <div class="row__start--flex1"><HomeFilterCompanies /></div>
            <div class="row__start--flex3">
                <HomeGoogleMaps :show="showMap" />
                <div class="responsive--show">
                    <div
                        class="row__start mt-15"
                        @click.stop="showMap = !showMap"
                    >
                        <GoogleSvg />
                        <p class="text__primary ml-8">
                            {{
                                showMap
                                    ? "Kartenansicht entfernen"
                                    : "Kartenansicht"
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="primary__line"></div>
        <p class="mt-15">
            Die nachfolgenden Aussteller Seiten sind kein Material der CONNECT -
            Ausbildungsmesse GmbH. Es handelt sich um Informationen und Material
            der dargestellten Unternehmen.
        </p>
        <div class="row__spacebetween">
            <h2>Hier findest Du Deine Suchergebnisse:</h2>
            <p class="text__grey">Anzahl: {{ availableCompanies.length }}</p>
        </div>

        <HomeCompanyResults />
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    components: {},
    name: "Home",
    data() {
        return {
            showMap: false,
        };
    },
    methods: {
        ...mapActions(["getDashboardOptions"]),
    },
    created() {
        this.getDashboardOptions();
    },
    computed: {
        ...mapGetters(["availableCompanies"]),
    },
};
</script>
